import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import $ from 'jquery';

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';


  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);


window.addEventListener('load', () => {
  // Is service worker available?
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js').then(registration => {
      console.log('Service worker registration succeeded:', registration);
    },
    /*catch*/ error => {
      console.error(`Service worker registration failed: ${error}`);
    }
  );
  } else {
    console.error('Service workers are not supported.');
  }
});

document.addEventListener("DOMContentLoaded", () => {
/* $(document).ready(function() {
 */     
  //$('body').css("background-image", "url('images/inicio_background_1.gif')"); 
  $('body').css('background-color', "#030303");												  

  var window_width = $( window ).width();
  var window_height = $( window ).height();
  var max_lado = 0;
  var sizecube_base = max_lado;
  var sizecube_altura = sizecube_base/1.5;
  var sizecube_desplazamient_XZ = sizecube_base/2;
  var sizecube_desplazamiento_Y = sizecube_altura/4;
  
  if (window_width >= window_height) {
      
    max_lado = Math.sqrt((Math.pow(window_height, 2)/2));  	
    sizecube_base = max_lado;
    sizecube_altura = sizecube_base/1.5;
    sizecube_desplazamient_XZ = sizecube_base/2;
    sizecube_desplazamiento_Y = sizecube_altura/4;

    document.documentElement.style.setProperty('--sizecube_2', sizecube_desplazamient_XZ + 'px');
    document.documentElement.style.setProperty('--sizecube_cateto', sizecube_altura + 'px');
    document.documentElement.style.setProperty('--sizecube', sizecube_base + 'px');
    document.documentElement.style.setProperty('--sizecube_4', sizecube_desplazamiento_Y + 'px');
    $('.viewport').css('left', (window_width - (max_lado)) / 2 + 'px' );
    $('.viewport').css('top', (window_height - (max_lado/1.5)) / 2 + 'px' );
    $('.scroll_down_portada').css('font-size', 16 + 'px' );
      
  }

  if (window_width < window_height) {
        
    max_lado = Math.sqrt((Math.pow(window_width, 2)/2));
    sizecube_base = max_lado;
    sizecube_altura = sizecube_base/1.5;
    sizecube_desplazamient_XZ = sizecube_base/2;
    sizecube_desplazamiento_Y = sizecube_altura/4;
    document.documentElement.style.setProperty('--sizecube_2', sizecube_desplazamient_XZ + 'px');
    document.documentElement.style.setProperty('--sizecube_cateto', sizecube_altura + 'px');
    document.documentElement.style.setProperty('--sizecube', sizecube_base + 'px');
    document.documentElement.style.setProperty('--sizecube_4', sizecube_desplazamiento_Y + 'px');
    $('.viewport').css('left', (window_width - (max_lado)) / 2 + 'px' );
    $('.viewport').css('top', (window_height - (max_lado/1.5)) / 2 + 'px' );
    $('.scroll_down_portada').css('font-size', 24 + 'px' );
        
  }

  if (window_width < 800) {          
        
    $('.bottom_side_icon').each(function() { 

        $(this).css('font-size', (window_width/800) * 1.2 + 'em' );

    });	  
        
  }

  if (window_height < 640 && window_width >= 800) {          
        
    $('.bottom_side_icon').each(function() { 

        $(this).css('font-size', (window_height/680) * 1.2 + 'em' );
        $(this).css('top', (window_height/680) * 33 + '%' );
        $(this).css('left', (window_height/680) * 33 + '%' );

    });	  
        
  }




  $(window).on('resize', function(){

    var window_width = $( window ).width();
    var window_height = $( window ).height();

    if (window_width >= window_height) {  
    
      max_lado = Math.sqrt((Math.pow(window_height, 2)/2));  	
      sizecube_base = max_lado;
      sizecube_altura = sizecube_base/1.5;
      sizecube_desplazamient_XZ = sizecube_base/2;
      sizecube_desplazamiento_Y = sizecube_altura/4;

      document.documentElement.style.setProperty('--sizecube_2', sizecube_desplazamient_XZ + 'px');
      document.documentElement.style.setProperty('--sizecube_cateto', sizecube_altura + 'px');
      document.documentElement.style.setProperty('--sizecube', sizecube_base + 'px');
      document.documentElement.style.setProperty('--sizecube_4', sizecube_desplazamiento_Y + 'px');
      $('.viewport').css('left', (window_width - (max_lado)) / 2 + 'px' );
      $('.viewport').css('top', (window_height - (max_lado/1.5)) / 2 + 'px' );
      $('.scroll_down_portada').css('font-size', 16 + 'px' );
          
    }

    if (window_width < window_height) {  
        
      max_lado = Math.sqrt((Math.pow(window_width, 2)/2));
      sizecube_base = max_lado;
      sizecube_altura = sizecube_base/1.5;
      sizecube_desplazamient_XZ = sizecube_base/2;
      sizecube_desplazamiento_Y = sizecube_altura/4;
      document.documentElement.style.setProperty('--sizecube_2', sizecube_desplazamient_XZ + 'px');
      document.documentElement.style.setProperty('--sizecube_cateto', sizecube_altura + 'px');
      document.documentElement.style.setProperty('--sizecube', sizecube_base + 'px');
      document.documentElement.style.setProperty('--sizecube_4', sizecube_desplazamiento_Y + 'px');
      $('.viewport').css('left', (window_width - (max_lado)) / 2 + 'px' );
      $('.viewport').css('top', (window_height - (max_lado/1.5)) / 2 + 'px' );
      $('.scroll_down_portada').css('font-size', 24 + 'px' );
      

    }

    if (window_width < 800) {          
        
      $('.bottom_side_icon').each(function() { 

          $(this).css('font-size', (window_width/800) * 1.2 + 'em' );

      });	  
        
    } else {$('.bottom_side_icon').each(function() { 

        $(this).css('font-size', 1.2 + 'em' );
        $(this).css('top', 33 + '%' );
        $(this).css('left', 33 + '%' );

      });

    }

    if (window_height < 640) {

      if (window_width < 800) {

        $('.bottom_side_icon').each(function() { 

          $(this).css('font-size', (window_height/800) * 1.2 + 'em' );
          $(this).css('top', (window_height/800) * 33 + '%' );
          $(this).css('left', (window_height/800) * 33 + '%' );

        });	  
            
      } else {          
        
        $('.bottom_side_icon').each(function() { 

            $(this).css('font-size', (window_height/640) * 1.2 + 'em' );
            $(this).css('top', (window_height/640) * 33 + '%' );
            $(this).css('left', (window_height/640) * 33 + '%' );

        });

      }  
        
    }     

  });


  var events = new Events();
  events.add = function(obj) {
    obj.events = { };
  }
  events.implement = function(fn) {
    fn.prototype = Object.create(Events.prototype);
  }

  function Events() {
    this.events = { };
  }

  Events.prototype.on = function(name, fn) {
    var events = this.events[name];
    // eslint-disable-next-line
    if (events == undefined) {
      this.events[name] = [ fn ];
      this.emit('event:on', fn);
    } else {
      // eslint-disable-next-line
      if (events.indexOf(fn) == -1) {
        events.push(fn);
        this.emit('event:on', fn);
      }
    }
    return this;
  }
  Events.prototype.once = function(name, fn) {
    var events = this.events[name];
    fn.once = true;
    if (!events) {
      this.events[name] = [ fn ];
      this.emit('event:once', fn);
    } else {
      // eslint-disable-next-line
      if (events.indexOf(fn) == -1) {
        events.push(fn);
        this.emit('event:once', fn);
      }
    }
    return this;
  }
  Events.prototype.emit = function(name, args) {
    var events = this.events[name];
    if (events) {
      var i = events.length;
      while(i--) {
        if (events[i]) {
          events[i].call(this, args);
          if (events[i].once) {
            delete events[i];
          }
        }
      }
    }
    return this;
  }
  Events.prototype.unbind = function(name, fn) {
    if (name) {
      var events = this.events[name];
      if (events) {
        if (fn) {
          var i = events.indexOf(fn);
          // eslint-disable-next-line
          if (i != -1) {
            delete events[i];
          }
        } else {
          delete this.events[name];
        }
      }
    } else {
      delete this.events;
      this.events = { };
    }
    return this;
  }

  var userPrefix;
  // eslint-disable-next-line
  var prefix = (function () {
    var styles = window.getComputedStyle(document.documentElement, ''),
      pre = (Array.prototype.slice
        .call(styles)
        .join('')
        // eslint-disable-next-line 
        .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
      )[1],
      dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))[1];
    userPrefix = {
      dom: dom,
      lowercase: pre,
      css: '-' + pre + '-',
      js: pre[0].toUpperCase() + pre.substr(1)
    };
  })();

  function bindEvent(element, type, handler) {
    if(element.addEventListener) {
      element.addEventListener(type, handler, false);
    } else {
      element.attachEvent('on' + type, handler);
    }
  }

  function Viewport(data) {
    events.add(this);

    var self = this;

    this.element = data.element;
    this.fps = data.fps;
    this.sensivity = data.sensivity;
    this.sensivityFade = data.sensivityFade;
    this.touchSensivity = data.touchSensivity;
    this.speed = data.speed;

    this.lastX = 0;
    this.lastY = 0;
    this.mouseX = 0;
    this.mouseY = 0;
    this.distanceX = 0;
    this.distanceY = 0;
    this.positionX = 1118;
    this.positionY = 140;
    this.torqueX = 0;
    this.torqueY = 0;

    this.down = false;
    this.upsideDown = false;

    this.previousPositionX = 0;
    this.previousPositionY = 0;

    this.currentSide = 0;
    this.calculatedSide = 0;


    bindEvent(document, 'mousedown', function() {
      self.down = true;
    });

    bindEvent(document, 'mouseup', function() {
      self.down = false;
    });
    
    bindEvent(document, 'keyup', function() {
      self.down = false;
    });

    bindEvent(document, 'mousemove', function(e) {
      self.mouseX = e.pageX;
      self.mouseY = e.pageY;
    });

    bindEvent(document, 'touchstart', function(e) {

      self.down = true;
      if (e.touches) {
        e = e.touches[0]
      };
      //e.touches ? e = e.touches[0] : null;
      self.mouseX = e.pageX / self.touchSensivity;
      self.mouseY = e.pageY / self.touchSensivity;
      self.lastX  = self.mouseX;
      self.lastY  = self.mouseY;
    });

    bindEvent(document, 'touchmove', function(e) {
      if(e.preventDefault) { 
        //e.preventDefault();
      }
      // eslint-disable-next-line
      if(e.touches.length == 1) {

        if (e.touches) {
          e = e.touches[0]
        };
        //e.touches ? e = e.touches[0] : null;

        self.mouseX = e.pageX / self.touchSensivity;
        self.mouseY = e.pageY / self.touchSensivity;

      }
    });

    bindEvent(document, 'touchend', function(e) {
      self.down = false;
    });  

    setInterval(this.animate.bind(this), this.fps);

  }

  events.implement(Viewport);
  Viewport.prototype.animate = function() {

    this.distanceX = (this.mouseX - this.lastX);
    this.distanceY = (this.mouseY - this.lastY);

    this.lastX = this.mouseX;
    this.lastY = this.mouseY;

    if(this.down) {
      this.torqueX = this.torqueX * this.sensivityFade + (this.distanceX * this.speed - this.torqueX) * this.sensivity;
      this.torqueY = this.torqueY * this.sensivityFade + (this.distanceY * this.speed - this.torqueY) * this.sensivity;
    }

    if(Math.abs(this.torqueX) > 1.0 || Math.abs(this.torqueY) > 1.0) {
      if(!this.down) {
        this.torqueX *= this.sensivityFade;
        this.torqueY *= this.sensivityFade;
      }

      this.positionY -= this.torqueY;

      if(this.positionY > 360) {
        this.positionY -= 360;
      } else if(this.positionY < 0) {
        this.positionY += 360;
      }

      if(this.positionY > 90 && this.positionY < 270) {
        this.positionX -= this.torqueX;

        if(!this.upsideDown) {
          this.upsideDown = true;
          this.emit('upsideDown', { upsideDown: this.upsideDown });
        }

      } else {

        this.positionX += this.torqueX;

        if(this.upsideDown) {
          this.upsideDown = false;
          this.emit('upsideDown', { upsideDown: this.upsideDown });
        }
      }

      if(this.positionX > 360) {
        this.positionX -= 360;
      } else if(this.positionX < 0) {
        this.positionX += 360;
      }

      if(!(this.positionY >= 46 && this.positionY <= 130) && !(this.positionY >= 220 && this.positionY <= 308)) {
        if(this.upsideDown) {
          if(this.positionX >= 42 && this.positionX <= 130) {
            this.calculatedSide = 3;
          } else if(this.positionX >= 131 && this.positionX <= 223) {
            this.calculatedSide = 2;
          } else if(this.positionX >= 224 && this.positionX <= 314) {
            this.calculatedSide = 5;
          } else {
            this.calculatedSide = 4;
          }
        } else {
          if(this.positionX >= 42 && this.positionX <= 130) {
            this.calculatedSide = 5;
          } else if(this.positionX >= 131 && this.positionX <= 223) {
            this.calculatedSide = 4;
          } else if(this.positionX >= 224 && this.positionX <= 314) {
            this.calculatedSide = 3;
          } else {
            this.calculatedSide = 2;
          }
        }
      } else {
        if(this.positionY >= 46 && this.positionY <= 130) {
          this.calculatedSide = 6;
        }

        if(this.positionY >= 220 && this.positionY <= 308) {
          this.calculatedSide = 1;
        }
      }
      // eslint-disable-next-line
      if(this.calculatedSide !== this.currentSide) {
        this.currentSide = this.calculatedSide;
        this.emit('sideChange');
      }

    }

    this.element.style[userPrefix.js + 'Transform'] = 'rotateX(' + this.positionY + 'deg) rotateY(' + this.positionX + 'deg)';
    // eslint-disable-next-line
    if(this.positionY != this.previousPositionY || this.positionX != this.previousPositionX) {
      this.previousPositionY = this.positionY;
      this.previousPositionX = this.positionX;

      this.emit('rotate');

    }

  }
  var viewport = new Viewport({
    element: document.getElementsByClassName('cube')[0],
    fps: 20,
    sensivity: .1,
    sensivityFade: .93,
    speed: 2,
    touchSensivity: 1.5
  });

  function Cube(data) {
    var self = this;

    this.element = data.element;
    this.sides = this.element.getElementsByClassName('side');

    this.viewport = data.viewport;
    this.viewport.on('rotate', function() {
      self.rotateSides();
    });
    
    this.viewport.on('sideChange', function() {
      self.sideChange();
    });
  }
  Cube.prototype.rotateSides = function() {
    // eslint-disable-next-line
    var viewport = this.viewport;

    /* if(viewport.positionY > 90 && viewport.positionY < 270) {
      this.sides[0].getElementsByClassName('cube-image')[0].style[userPrefix.js + 'Transform'] = 'rotate(' + (viewport.positionX + viewport.torqueX) + 'deg)';
      this.sides[5].getElementsByClassName('cube-image')[0].style[userPrefix.js + 'Transform'] = 'rotate(' + -(viewport.positionX + 180 + viewport.torqueX) + 'deg)';
    } else {
      this.sides[0].getElementsByClassName('cube-image')[0].style[userPrefix.js + 'Transform'] = 'rotate(' + (viewport.positionX - viewport.torqueX) + 'deg)';
      this.sides[5].getElementsByClassName('cube-image')[0].style[userPrefix.js + 'Transform'] = 'rotate(' + -(viewport.positionX + 180 - viewport.torqueX) + 'deg)';
    } */
  }
  Cube.prototype.upsideDown = function(obj) {
    // eslint-disable-next-line
    var deg = (obj.upsideDown == true) ? '180deg' : '0deg';
    var i = 5;

    while(i > 0 && --i) {
      this.sides[i].getElementsByClassName('cube-image')[0].style[userPrefix.js + 'Transform'] = 'rotate(' + deg + ')';
    }

  }
  Cube.prototype.sideChange = function() {

    for(var i = 0; i < this.sides.length; ++i) {
      this.sides[i].getElementsByClassName('cube-image')[0].className = 'cube-image';    
    }

    this.sides[this.viewport.currentSide - 1].getElementsByClassName('cube-image')[0].className = 'cube-image active';

  }

  new Cube({
    viewport: viewport,
    element: document.getElementsByClassName('cube')[0]
  });

});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

