import Cube from './Cube';

function App() {
  return (

    <div className="contenedor">

      <div className= "scroll_down_portada" style = {{textAlign: "left", paddingLeft: "1%", paddingRight: "2%", color: "white"}}> Pincha y arrastra en cualquier punto para girar</div>

      <Cube />
    </div>
        
      
  );
}

export default App;
