const Cube = () => {
    return (        
          
        <div className="viewport">
      
          <div className="cube">
      
            <div className="side cube-face-big">
      
              <div className="cube-image" ></div>
      
              <div className="side_quarter" style={{backgroundColor: "#0a272d"}} >
      
                  <div className="bottom_side_icon">
                      
                          <div style={{display: "inline", color: "white"}}>f1c1</div>
      
                  </div>
      
              </div>
      
              <div className="side_quarter">
      
                <div className="bottom_side_icon">
                    <br></br>
  
                    <div style={{display: "inline", color: "white"}}>f1c2</div>
  
                </div>
      
              </div>
      
              <div className="side_quarter">
      
                <div className="bottom_side_icon">
    
                  <i className="fa fa-info-circle fa-5x"></i> 
    
                  <div style={{display: "inline", color: "white"}}>
                    <a href="https://www.google.es">Sobre UBXsite</a>
                  </div>
    
                </div>
      
              </div>
      
              <div className="side_quarter" style= {{backgroundColor: "#0a272d"}}>
      
                <div className="bottom_side_icon"> 
    
                          <i className="fa fa-cubes fa-5x"></i>	
    
                        <div style={{display: "inline", color: "white"}}>f2c2</div>

                </div>
      
              </div>
      
              <div className="side_quarter"  style= {{backgroundColor: "#0a272d"}}>
                  
                  <div className="bottom_side_icon">
      
                            <i className="fa fa-envelope-o fa-5x"></i>	
      
                          <div style={{display: "inline", color: "white"}}>f3c1</div>
                      
                  </div>
      
              </div>
      
              <div className="side_quarter">      
      
                  <div className="bottom_side_icon">
      
                            <i className="fa fa-sign-in fa-5x"></i>
      
                          <div style={{display: "inline", color: "white"}}>f3c2</div>
      
                  </div>
      
              </div>
      
            </div>
      
            <div className="side" style= {{backgroundColor: "#ec8601"}}>
      
              <div className="cube-image">
      
              </div>
      
              <div className="side_text">
      
                  <div className = "side_title_text">Próxima publicación 1</div>
      
                  Espacio libre
      
                  <div className = "side_by_text">pronto en UnderBoX site</div>
      
              </div>
      
            </div>      
      
            <div className="side" style= {{backgroundColor: "#0a272d"}}>
      
              <div className="cube-image">     
                 
      
              </div>
      
              <div className="side_text">
      
                 <div className = "side_title_text">Próxima publicación 2</div>
      
                 Espacio libre
      
                  <div className = "side_by_text">pronto en UnderBoX site</div>
      
              </div>
      
            </div>
      
      
            <div className="side" style= {{backgroundColor: "#ec8601"}}>
      
              <div className="cube-image">      
                 
      
              </div>
      
              <div className="side_text">
      
                <div className = "side_title_text">Próxima publicación 3</div>
        
                Espacio libre

                <div className = "side_by_text">pronto en UnderBoX site</div>
              </div>
      
            </div>
      
            <div className="side"  style= {{backgroundColor: "#0a272d"}}>
      
              <div className="cube-image">
      
              </div>
      
              <div className="side_text">
      
                <div className = "side_title_text">Próxima publicación 4</div>
      
                Espacio libre

                <div className = "side_by_text">pronto en UnderBoX site</div>
      
              </div>
      
            </div>
      
            <div className="side cube-face-big">
      
              <div className="cube-image active">
                <img src="images/Logo_UBXsite_blanco.png" alt="logo UBX" style= {{width: "100%"}}></img>
              </div>
      
              <div className = "side_by_text fr" style= {{fontSize: "16px", color: "white", position: "absolute", bottom: 0, right: 0, maxWidth: "80%", display: "inline-block", marginBottom: "5%", marginRight: "5%"}}>Bienvenido a UnderBoX</div>
      
            </div>
      
          </div>
      
        </div>
    );
}
 
export default Cube;